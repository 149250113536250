import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { MomentService } from '@fc-core';
import moment from 'moment';
import { Moment } from 'moment';

@Component({
  selector: 'fc-controls',
  templateUrl: 'controls.component.html',
  styleUrls: ['./controls.component.scss'],
})
export class ControlsComponent {
  @Input() itemType: 'vehicle' | 'driver';
  @ViewChild('menuTrigger', { static: true }) calendarMenu;
  @ViewChild('search') search: ElementRef;

  constructor(private momentService: MomentService) {}

  @Input() openEditFunction: Function;
  @Output() previousDate: EventEmitter<string> = new EventEmitter();
  @Output() nextDate: EventEmitter<string> = new EventEmitter();
  @Output() dateSelected: EventEmitter<moment.Moment> = new EventEmitter();
  @Output() searchChange: EventEmitter<string> = new EventEmitter();
  selectedDate: Moment = this.momentService.moment();
  activeSearch: boolean;
  months: { name: string; date: Moment }[];

  @Input() set currentDate(date: Moment) {
    this.months = new Array(12).fill(0).map((val, index) => ({
      name: date.clone().month(index).format('MMM'),
      date: date.clone().month(index),
    }));
    this.selectedDate = date.clone();
  }

  selectMonth(month: Moment): void {
    this.calendarMenu.closeMenu();
    this.selectedDate = month.clone();
    this.dateSelected.emit(month.add(1, 'day'));
  }

  onPrevious(): void {
    this.previousDate.emit();
  }

  onNext(): void {
    this.nextDate.emit();
  }

  getCurrentMonth(month: Moment): boolean {
    return this.selectedDate.format('MM YY') === month.format('MM YY');
  }

  getSelectedMonth(month: Moment): boolean {
    return (
      this.momentService.moment().format('MM YY') === month.format('MM YY')
    );
  }

  onCloseSearch(): void {
    this.activeSearch = false;
    this.searchChange.emit('');
  }
}
